<template>
  <div class="sidebar">
    <v-card>
      <v-toolbar flat dark dense :color="color">
        <v-toolbar-title>Diary Details</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider></v-divider>

      <v-card-text class="">
        <div v-if="dialog">
          <v-form @submit.prevent="save" method="post" id="diary-form">
            <v-simple-table class="mb-5 no-hover">
              <tbody>
                <tr>
                  <td colspan="2" class="pt-4 pb-4">
                    <label class="pt-4 pb-4">Title*</label><br /><br />
                    <v-text-field
                      v-model="fields.title"
                      outlined
                      background-color="white"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="pt-4 pb-4">
                    <label class="pt-4 pb-4">Description</label><br /><br />
                    <tiptap v-model="fields.description" class="mb-8" />
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="pt-4 pb-4">
                    <label class="pt-4 pb-4">Due Date</label><br /><br />
                    <v-text-field
                      v-model="fields.due_date"
                      type="date"
                      outlined
                      background-color="white"
                      :error="errors.hasOwnProperty('due_date')"
                      :error-messages="errors['due_date']"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="pt-4 pb-4">
                    <label class="pt-4 pb-4">Assignee</label><br /><br />
                    <v-select
                      v-model="fields.assignee"
                      :items="users"
                      item-value="id"
                      item-text="full_name"
                      outlined
                      background-color="white"
                      :error="errors.hasOwnProperty('assignee')"
                      :error-messages="errors['assignee']"
                    ></v-select>
                  </td>
                </tr>

                <tr>
                  <td colspan="2" class="pt-4 pb-4">
                    <label class="pt-4 pb-4">Completed</label><br /><br />
                    <v-switch
                      v-model="fields.completed"
                      inset
                      :error="errors.hasOwnProperty('completed')"
                      :error-messages="errors['completed']"
                    ></v-switch>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-form>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="accent"
              text
              type="submit"
              :loading="loading"
              form="diary-form"
              >Save</v-btn
            >
          </v-card-actions>
        </div>
        <div v-else>Select a diary to see information</div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
//import DiaryDialog from "./DiaryDialog.vue";
import Tiptap from "@/views/components/Tiptap.vue";

export default {
  props: {
    type: String,
    color: {
      type: String,
    },
  },

  components: {
    Tiptap,
    // DiaryDialog,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      diary: {},
      fields: {
        title: null,
        type: this.type,
        completed: false,
        description: null,
        due_date: null,
      },
      errors: {},
    };
  },

  computed: {
    users() {
      return this.$store.state.users["users"];
    },
  },

  methods: {
    openForm(diary, type) {
      const appId = this.$route.params.id;
      const diaryId = diary.id;

      this.isEditing = true;

      this.$store.dispatch("sbpm/diariesStore/loadDiary", {
        appId,
        diaryId,
      });

      this.fields.assignee = diary.assigne_user ? diary.assigne_user.id : null;

      this.diary = diary;
      this.fields.type = type;
      this.fields.title = diary.title;
      this.fields.description = diary.description;
      this.fields.due_date = diary.due_date;
      this.fields.completed = diary.completed;

      this.dialog = true;
    },
    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.diaryId = this.diary.id;
      }

      this.$store
        .dispatch("sbpm/diariesStore/saveDiary", payload)
        .then(() => {
          if (this.isEditing) {
            this.$store.commit("showGlobalSnackbar", {
              open: true,
              text: "Task Successfully updated",
              color: "success",
            });
          }
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.loading = false;
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.no-hover tr:hover {
  background: transparent !important;
}
</style>
